export default {
  // PRODUCT
  AppProduct: '/produs',
  AppProductDesignPWA: '/design-pwa',
  AppProductMagento2core: '/magento-core',
  AppProductAutomation: '/automatizari',
  AppMarketplaceIntegrations: '/integrari-marketplace',
  AppMarketingIntegration: '/integrari-marketing',
  AppErpIntegrations: '/integrari-erp',
  AppProductIntegrations: '/integrari',
  AppProductProcess: '/proces',
  AppProductHosting: '/hosting',
  AppHostingArhitecture: '/hosting-arhitecture',
  AppWeMonitorEverything: '/monitorizare',
  AppSecurityFirst: '/securitate',
  AppProductGdprDataGovernanace: '/gdpr-protectia-datelor',
  // PRODUCT - END
  // SWITCHING
  AppSwitchingToZento: '/upgrade-la-zento',
  AppProcessForSwitching: '/proces-upgrade',
  AppReasonsForSwitching: '/motive-upgrade',
  // SWITCHING - END
  // WIKI
  AppWiki: '/wiki',
  AppWikiDesignGeneralDesignAndUx: '/wiki/design-si-ux/general-design',
  AppWikiDesignVision: '/wiki/design-si-ux/viziunea-design',
  AppWikiHostingArchitecture: '/wiki/hosting/arhitectura',
  AppWikiHostingM2kubernetes: '/wiki/hosting/m2-kubernetes',
  AppWikiHostingPwalambda: '/wiki/hosting/pwa-lambda',
  AppWikiHostingSecurity: '/wiki/hosting/securitate',
  AppWikiHostingStorageDatabase: '/wiki/hosting/stocare-si-baze-de-date',
  AppWikiHostingWhatiscloudhosting: '/wiki/hosting/ce-este-cloud-hosting',
  AppWikiOverviewPwa: '/wiki/general/ce-este-pwa',
  AppWikiOverviewTco: '/wiki/general/total-cost-of-ownership',
  AppWikiOverviewWhyzento: '/wiki/general/de-ce-zento',
  // WIKI - END
  // CASE STUDIES
  CaseStudies: '/studii-de-caz',
  CaseStudy: '/studiu-de-caz',
  // CASE STUDIES - END
  AppPriceSlider: '/preturi',
  AppDataProtectionAndPrivacy: '/politica-de-confidentialitate',
  AppPreContractualTerms: '/pre-contractual-terms',
  // ABOUT
  AppAbout: '/despre-noi',
  AppLeadershipTeam: '/echipa-fondatori',
  // ABOUT - END
  AppContactPage: '/contact',
  AppBlog: '/blog',
  AppBlogArticle: '/blog/article',
  // PARTNER
  AppPartner: '/parteneriate',
  AppAffiliate: '/afiliere',
  AppZentoCertifiedAffiliateFaq: '/zento-certified-affiliate-faq',
  AppBecomePartner: '/devino-partener',
  AppZentoCertifiedBusinessPartnerFaq: '/zento-certified-business-partner-faq',
  AppFindPartner: '/cauta-partener',
  // PARTNER - END
  // CAREERS
  AppCareers: '/careers',
  AppCustomerSupportSpecialist: '/customer-support-specialist',
  AppCustomerSupportSpecialistPL: '/customer-support-specialist-pl',
  AppMagento2Develeper: '/senior-magento2-developer',
  AppMarketingSpecialist: '/content-marketing-specialist',
  AppQaSpecialist: '/qa-specialist',
  AppSalesReprezentative: '/sales-representative',
  AppSeniorReactDeveloper: '/senior-react-developer',
  AppWebDesigner: '/web-designer',
  // CAREERS - END
  Blog: '/blog',
  BlogArticle: '/blog/article',
  // AWS
  AppAwsmkpSubscribe: '/awsmkp-subscribe',
  // AWS - END
  // LPS
  AppLps: '/lp',
  AppLPM1M2: '/lp/upgrade-magento1-to-magento2',
  LPCampaign1: '/ecommerce-magento-2-saas',
  // LPS - END
};
